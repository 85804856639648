<template>
  <div class="home">
    <div class="header">
      <div class="top-bar">
        <div class="logo-container">
          <img src="../assets/9cloud.logo.144.png" style="height: 30px;">
          <span style="font-size: x-large; font-weight: 400; color: #7d888e;">9CLOUD</span>
        </div>
        <div style="flex: 1;"></div>
        <div class="menu">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                   background-color="#fbfdff">
            <el-menu-item index="1">首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">产品中心</template>
              <el-menu-item index="2-1">佣金核对</el-menu-item>
              <el-menu-item index="2-2">智能证件识别</el-menu-item>
              <el-menu-item index="2-3">发票助手</el-menu-item>
              <el-submenu index="2-4">
                <template slot="title">小程序</template>
                <el-menu-item index="2-4-1">小云当前</el-menu-item>
                <el-menu-item index="2-4-2">小云客房助手</el-menu-item>
                <el-menu-item index="2-4-3">小云报修助手</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-menu-item index="3">用户案例</el-menu-item>
            <el-menu-item index="4">关于</el-menu-item>
            <el-menu-item index="5">联系我们</el-menu-item>
          </el-menu>
        </div>
        <div style="padding: 10px 0 0 30px;">
          <el-button type="primary">开始使用</el-button>
        </div>
      </div>

      <div style="display: flex; align-items: center; flex-direction: row;
        justify-content: center;">
        <span style="font-family: Helvetica;">
          <h4>Powerful Website Hosting Services</h4>
        </span>
      </div>
      <div style="display: flex; align-items: center; flex-direction: row;
       justify-content: center;">
        <img src="../assets/main-bg.png" alt="" style="height: 260px;">
      </div>
    </div>

    <div class="main-container">
      <div style="display: flex; align-items: center; flex-direction: row;
        justify-content: center;">
        <span style="font-family: Helvetica;">
          <h2>We Provide Many Services That’s why 9Cloud is Always Best</h2>
        </span>
      </div>

      <div style="display: flex; flex-direction: row; width: 1000px; margin: 0 auto;">
        <div class="flex-item">
          <img src="../assets/1-1.png" alt="">
          <div>
            <h2>Easy To Search</h2>
            <p>
              Easy Search is a search and discovery tool that allows you to simultaneously search
               more than one database. In the Main Menu, select Tools then Extensions
            </p>
          </div>
        </div>
        <div class="flex-item">
          <img src="../assets/2-2.png" alt="">
          <div>
            <h2>Build Better & Faster</h2>
            <p>
              When it comes to creating the software that drives your digital enterprise, it is
              possible to be fast while still getting top-quality results. there’s a way to be
              both good and fast.
            </p>
          </div>
        </div>
        <div class="flex-item">
          <img src="../assets/3-3.png" alt="">
          <div>
            <h2>Predictable Pricing</h2>
            <p>
              Easy Search is a search and discovery tool that allows you to simultaneously search
              more than one database. In the Main Menu, select Tools then Extensions
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div style="display: flex; flex-direction: row;">
        <div style="width: 33.3%;"></div>
        <div style="width: 33.3%; text-align: center;">
          © Copyright 9Cloud. All Rights reserved.
        </div>
        <div style="width: 33.3%; display: flex; flex-direction: row; justify-content: center;">
          <img src="@/assets/备案图标.png" alt="">
          <el-link href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034026" target="_blank"><span class="lh">京公网安备 11010802034026号</span></el-link>
          <el-link style="margin-left: 20px;" href="https://beian.miit.gov.cn" target="_blank"><span class="lh">京ICP备17072322号</span></el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {
    // HelloWorld,
  },
  data() {
    return {
      activeIndex: '1',
    };
  },
};
</script>

<style lang="less">
  .home {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .main-container {
    flex: 1;
  }

  .footer {
    padding: 20px;
  }

  .header {
    width: 100%;
    height: 500px;
    background-color: #fbfdff;
    position: relative;
  }

  .top-bar {
    padding: 20px 80px;
    display: flex;
    flex-direction: row;
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flex-item {
    width: 33.3%;
    padding: 0 20px;
  }
</style>
